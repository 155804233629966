<template>
  <div class="refund-request scroll-container">
    <CategoryTitle :category="category" />
    <v-row
      no-gutters
      class="text-body-2 mt-5 mb-2 headers grey lighten-1 rounded-md py-1"
      align="center"
      justify="space-between"
    >
      <v-col cols="8" sm="10" md="8">
        {{ $t("lists.header.product") }}
      </v-col>
      <v-col cols="4" sm="2" md="2">
        {{ $t("lists.header.qty") }}
      </v-col>
    </v-row>
    <v-form
      ref="formRef"
      lazy-validation
      @submit.stop.prevent="saveRefund"
      v-if="refundItems != null"
    >
      <div
        v-for="(item, index) in refundItems.wishlistItems"
        :key="index"
        class="mt-3"
      >
        <RefundItem
          :item="item"
          :readOnly="false"
          @updateItemImg="updateItemImg(index, $event)"
          @updateNewWeight="updateNewWeight(item, $event)"
          @updateNewQuantity="updateNewQuantity(item, $event)"
        />
      </div>
      <div>
        <p class="mb-0 mt-6 font-weight-600">
          {{ $t("refund.selectRefundType") }}
        </p>
        <v-radio-group
          v-model="refundItems.wishlistTypeId"
          row
          hide-details
          class="mt-1 font-weight-600"
        >
          <v-radio
            v-for="option in refundOptionsArray"
            :key="option.key"
            :label="option.label"
            :value="option.key"
            color="default"
          ></v-radio>
        </v-radio-group>
      </div>
      <ResponseMessage class="mt-3" :response="response" />
      <v-row no-gutters class="backbtn-container mt-5 justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="allQuantitiesZero || loading"
          type="submit"
        >
          <span v-html="$t('refund.sentRequests')"></span>
        </v-btn>
      </v-row>
    </v-form>
    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>
<style lang="scss">
.refund-request {
  .headers {
    font-weight: 600;
    padding: 0 16px;
  }
}
</style>
<script>
import RefundService from "@/service/refundService";
import CategoryTitle from "@/components/category/CategoryTitle";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

import RefundItem from "@/components/orders/RefundItem.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";

import Vue from "vue";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "RefundRequest",
  data() {
    return {
      refundItems: null,
      orderId: null,
      refundOptions: {
        6: "Rimborso"
        //7: "Sostituzione"
      },
      key: 0,
      loading: true,
      response: {}
    };
  },
  mixins: [reload, categoryMixin],
  components: {
    CategoryTitle,
    RefundItem,
    ResponseMessage
  },

  computed: {
    refundOptionsArray() {
      return Object.entries(this.refundOptions).map(([key, option]) => ({
        key: key,
        label: option
      }));
    },
    allQuantitiesZero() {
      this.key;
      if (this.refundItems && this.refundItems.wishlistItems) {
        return this.refundItems.wishlistItems.every(
          item => item.newQuantity === 0 && item.newWeight === 0
        );
      }
      return true;
    }
  },
  methods: {
    async loadRefund() {
      let res = await RefundService.addNewRefund(
        this.orderId,
        this.refundItems
      );
      if (res) {
        this.refundItems = res;
        this.refundItems.wishlistItems.forEach(item => {
          item.newQuantity = 0;
          item.newWeight = 0;
        });
      }
      this.loading = false;
    },
    updateNewWeight(item, newWeight) {
      this.key;
      this.refundItems.wishlistItems.forEach(refundItem => {
        if (
          item.wishlistItemInfo.ORDER_ITEM_ID ==
          refundItem.wishlistItemInfo.ORDER_ITEM_ID
        ) {
          refundItem.newWeight = newWeight;
        }
      });

      this.key++;
    },
    updateNewQuantity(item, newQuantity) {
      this.key;
      this.refundItems.wishlistItems.forEach(refundItem => {
        if (
          item.wishlistItemInfo.ORDER_ITEM_ID ==
          refundItem.wishlistItemInfo.ORDER_ITEM_ID
        ) {
          refundItem.newQuantity = newQuantity;
        }
      });

      this.key++;
    },
    saveRefund() {
      try {
        if (this.$refs.formRef.validate()) {
          this.loading = true;
          let tempOrder = cloneDeep(this.refundItems);
          tempOrder.wishlistItems.forEach(item => {
            if (item.newWeight && item.newWeight > 0) {
              item.quantity = 1;
              item.weight = item.newWeight;
              delete item.newWeight;
            }
            if (item.newQuantity && item.newQuantity > 0) {
              item.quantity = item.newQuantity;
              delete item.newQuantity;
            }
            if (
              item.product.productInfos.TIPOLOGIA != "Pezzo" &&
              item.weight === 0
            ) {
              // if weight is 0, we get quantity 1 from server
              item.quantity = 0;
            }
            delete item.product;
          });
          RefundService.uploadRefund(tempOrder).then(data => {
            if (data.response.status == 0) {
              this.$router.push({
                path: `/profile/order-refunds/${data.data.listId}`
              });
            } else {
              this.loading = false;
              this.response = data.response;
            }
          });
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    updateItemImg(index, img) {
      Vue.set(
        this.refundItems.wishlistItems[index].wishlistItemInfo,
        "IMAGE",
        img
      );
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId;
    this.refundItems = this.$route.query.itemId;
    this.loadRefund();
  }
};
</script>
